const isProd = (): boolean => {
	return window.location.hostname === "staging.telenor.no" ||
		window.location.hostname === "telenor.no" ||
		window.location.hostname === "www.telenor.no"
		? true
		: false;
};

export default function (baseUrl: string): string {
	if (isProd()) {
		return `https://store.telenor.no/omnichannel/${baseUrl}`;
	} else {
		return `https://omnichannel.api.test.apc.telenor.net/${baseUrl}`;
	}
}
