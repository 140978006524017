import axios from "axios";
import type { DealerStore } from "~/components/ecommerce/types/DealerStore";
import oneshopApi from "./oneshop-adapter";

const getClosest = async (
	isConsumer: boolean,
	latitude?: number,
	longitude?: number,
	query: string = "",
): Promise<DealerStore[]> => {
	const url = oneshopApi(`v1/dealers/search?isConsumer=${isConsumer}&limit=100`);
	const params: Record<string, string> = {};

	if (query) params.query = query;
	if (latitude != null) params.lat = latitude.toString();
	if (longitude != null) params.lon = longitude.toString();

	try {
		const { data } = await axios.get(url, { params });

		// Filter based on whether it's consumer or business
		const filteredData = data.filter((store: DealerStore) => {
			const name = store.name || "";
			if (isConsumer) {
				// Consumer: keep only those starting with "Telenorbutikken"
				return name.startsWith("Telenorbutikken");
			} else {
				// Business: exclude those starting with "Telenorbutikken"
				return !name.startsWith("Telenorbutikken");
			}
		});

		return filteredData;
	} catch (e) {
		console.error(e);
		return [];
	}
};

export default {
	getClosest,
};
