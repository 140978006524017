<template>
	<div>
		<DealerSearchPrivat v-if="segment === 'privat'" />
		<DealerSearchBusiness v-else />
	</div>
</template>

<script>
import GizmoComponentMixin from "~/mixins/platform/GizmoComponentMixin.js";
import DealerSearchPrivat from "./DealerSearchConsumer.vue";
import DealerSearchBusiness from "./DealerSearchBusiness.vue";

export default defineNuxtComponent({
	name: "DealerSearchVue",
	mixins: [GizmoComponentMixin],
	components: {
		DealerSearchPrivat,
		DealerSearchBusiness,
	},
	computed: {
		segment() {
			return this.$store.getters["getGlobalSegment"];
		},
	},
});
</script>

<style lang="scss" scoped></style>
