<template>
	<div class="dealer-search">
		<form
			@submit.prevent="search"
			class="search"
		>
			<TnInputText
				:placeholder="placeholder"
				autofocus
				text="Søk etter sted"
				v-model="query"
				:disabled="loading"
				class="search-input"
				iconLeft="location"
				hide-details
				:loading="loading"
			>
				<template #after>
					<TnButton
						class="margin-left-s"
						iconRight="search"
						size="s"
						@click="search"
						>Søk</TnButton
					>
				</template>
			</TnInputText>
		</form>
		<div class="margin-vertical-m">
			<TnButton
				icon-left="near-me-off"
				@click="useLocation"
				:size="isMobile ? 'xs' : 's'"
				secondary
				:loading="locationPending"
				:disabled="locationPending || (!query && placeholder === 'Min plassering')"
				>Bruk min plassering
			</TnButton>
		</div>
		<div class="results">
			<DealerSearchStore
				v-for="(store, idx) in results"
				:key="`store-${idx}`"
				:store="store"
				:interactive="interactive"
			/>
		</div>
	</div>
</template>

<script>
import dealerSearch from "~/integrations/dealer-search";
import { mapState } from "pinia";
import telenorbutikkenOpeninghours from "~/helpers/ecommerce/telenorbutikken-openinghours";
import { useGeolocationStore } from "~/pinia/platform/geolocation/geolocation.ts";

export default defineNuxtComponent({
	name: "DealerSearchBusiness",

	props: {
		maxResults: {
			type: Number,
			default: 10,
		},
		interactive: {
			type: Boolean,
		},
	},

	data() {
		return {
			query: "",
			loading: false,
			results: [],
			locationPending: false,
		};
	},

	computed: {
		...mapState(useGeolocationStore, ["position"]),
		placeholder() {
			if (this.position.latitude) return "Min plassering";
			return "Stedsnavn";
		},
		isConsumer() {
			return this.$store.getters["getGlobalSegment"] === "privat";
		},
		isMobile() {
			const mobileBreakpoint = 768;
			return window?.innerWidth < mobileBreakpoint;
		},
	},

	methods: {
		async search() {
			this.loading = true;
			const results = await dealerSearch.search(
				this.query,
				0,
				this.content?.maxResultsPrPage || this.maxResults,
				this.isConsumer,
			);
			this.results = results
				.filter((res) => [3681, 4759].indexOf(res.dealerId) < 0)
				.map((res) => {
					res.openingHours = telenorbutikkenOpeninghours[res.dealerId];
					return res;
				});
			this.loading = false;
		},
		async getClosestStores() {
			this.loading = true;
			const results = await dealerSearch.getClosest(
				this.position.latitude,
				this.position.longitude,
				this.content?.maxResultsPrPage || this.maxResults,
				this.isConsumer,
			);
			this.results = results
				.filter((res) => [3681, 4759].indexOf(res.dealerId) < 0)
				.map((res) => {
					res.openingHours = telenorbutikkenOpeninghours[res.dealerId];
					return res;
				});
			this.loading = false;
		},
		async useLocation() {
			this.locationPending = true;

			await useGeolocationStore().requestBestAvailablePosition(false);

			this.locationPending = false;

			if (this.position.latitude) {
				this.query = "";
				this.getClosestStores();
			}
		},
	},

	async mounted() {
		this.loading = true;

		if (!this.position.latitude) {
			await useGeolocationStore().requestBestAvailablePosition(false);
		}

		if (this.position.latitude) {
			await this.getClosestStores();
		} else {
			await this.search("");
		}

		this.loading = false;
	},
});
</script>

<style lang="scss" scoped></style>
