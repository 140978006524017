<template>
	<div class="dealer-search">
		<form class="search">
			<TnInputText
				:placeholder="placeholder"
				autofocus
				text="Finn butikk"
				v-model="query"
				:disabled="loading"
				class="search-input"
				iconLeft="location"
				hide-details
				:loading="loading"
			>
				<template #after>
					<TnButton
						class="margin-left-s"
						iconRight="search"
						size="s"
						@click="search"
						>Søk</TnButton
					>
				</template>
			</TnInputText>
		</form>
		<div class="margin-vertical-m">
			<TnButton
				icon-left="near-me-off"
				@click="useLocation"
				:size="isMobile ? 'xs' : 's'"
				secondary
				:loading="locationPending"
				:disabled="locationPending"
				>Bruk min plassering
			</TnButton>
		</div>
		<div class="results">
			<DealerSearchStoreConsumer
				v-for="(store, idx) in visibleResults"
				:key="`store-${idx}`"
				:store="store"
				:interactive="interactive"
			/>
			<div
				class="margin-top-s"
				@click="showMore"
			>
				<LinkInline
					size="s"
					v-if="hasMoreResult"
					>Vis flere</LinkInline
				>
			</div>
		</div>
	</div>
</template>

<script>
import dealerSearch from "~/integrations/dealer-search-consumer";
import { mapState } from "pinia";
import { useGeolocationStore } from "~/pinia/platform/geolocation/geolocation.ts";

export default defineNuxtComponent({
	name: "DealerSearch",

	props: {
		maxResults: {
			type: Number,
			default: 10,
		},
		interactive: {
			type: Boolean,
		},
	},

	data() {
		return {
			query: "",
			loading: false,
			results: [],
			locationPending: false,
			visibleCount: 5,
		};
	},

	computed: {
		...mapState(useGeolocationStore, ["position"]),
		placeholder() {
			return "Søk etter postnummer eller by";
		},
		isConsumer() {
			return this.$store.getters["getGlobalSegment"] === "privat";
		},
		isMobile() {
			const mobileBreakpoint = 768;
			return window?.innerWidth < mobileBreakpoint;
		},
		visibleResults() {
			return this.results.slice(0, this.visibleCount);
		},
		hasMoreResult() {
			return this.visibleCount > 0 && this.visibleCount < 10 && this.visibleCount < this.results.length;
		},
		showMore() {
			this.visibleCount += 5; // Show all results
		},
	},

	async mounted() {
		this.useLocation();
	},
	methods: {
		async search() {
			this.visibleCount = 5;
			this.loading = true;

			const query = this.query.trim();
			this.results = await dealerSearch.getClosest(this.isConsumer, null, null, query);
			this.loading = false;
		},

		async useLocation() {
			this.loading = true;
			this.locationPending = true;

			await useGeolocationStore().requestBestAvailablePosition(false);

			this.locationPending = false;

			if (this.position.latitude) {
				this.query = "";
				this.results = await dealerSearch.getClosest(
					this.isConsumer,
					this.position.latitude,
					this.position.longitude,
					"",
				);
			}
			this.loading = false;
		},
	},
});
</script>

<style lang="scss" scoped></style>
