<template>
	<div class="dealer-search-store">
		<h2>{{ name }}</h2>

		<div class="margin-bottom-m">
			<a
				class="phone-number"
				:href="'tel:' + telephoneNumber"
				>Tel: {{ telephoneNumber }}</a
			>
		</div>
		<div class="grid-columns-2">
			<div>
				<template v-if="distanceInKilometers">
					<LinkInline :to="googleMapsLink">{{ distanceInKilometers }}</LinkInline>
					<div>{{ location }}</div>
				</template>
				<template v-else>
					<LinkInline :to="googleMapsLink">{{ location }}</LinkInline>
				</template>
			</div>
			<div>
				<div class="opening-hours">{{ openingHoursToday }}</div>
				<div v-if="interactive">
					<TnButton
						size="xs"
						secondary
						class="margin-top-xs"
						v-if="isMyStore"
						disabled
						>Min Telenorbutikk</TnButton
					>
					<TnButton
						size="xs"
						secondary
						class="margin-top-xs"
						v-else
						@click="setAsStore"
						>Sett som min butikk</TnButton
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import capitalize from "~/helpers/formatting/capitalize";
import { mapState } from "pinia";
import { useGeolocationStore } from "~/pinia/platform/geolocation/geolocation.ts";

export default defineNuxtComponent({
	name: "DealerSearchStoreConsumer",

	props: {
		store: {
			type: Object,
			required: true,
		},
		interactive: {
			type: Boolean,
		},
	},

	computed: {
		...mapState(useGeolocationStore, ["position"]),
		name() {
			return this.store.name;
		},
		location() {
			return capitalize(this.store.address.postalPlace);
		},
		telephoneNumber() {
			return this.store.phoneNumber;
		},
		distanceInKilometers() {
			if (this.store.distanceFromUser === null || isNaN(this.store.distanceFromUser)) {
				return `${this.store.address.postalCode} ${this.store.address.postalArea}`;
			}

			return `${this.store.distanceFromUser} km unna`;
		},
		openingHoursToday() {
			return `Man-fre ${this.store.openingHours.weekDays.openingTime} - ${this.store.openingHours.weekDays.closingTime} (Lør ${this.store.openingHours.saturday.openingTime} - ${this.store.openingHours.saturday.closingTime})`;
		},
		googleMapsLink() {
			return `https://www.google.com/maps/search/?api=1&query=${this.store.name}`;
		},
		isMyStore() {
			if (!this.$store.state.telenorbutikken.closestStore) return false;
			return this.store.dealerId === this.$store.state.telenorbutikken.closestStore.dealerId;
		},
		isGeoLocationSet() {
			return this.store.position.latitude;
		},
	},

	methods: {
		setAsStore() {
			this.$store.commit("telenorbutikken/setClosestStore", this.store);
			this.$store.commit("telenorbutikken/setOverride", true);
		},
	},
});
</script>

<style lang="scss" scoped>
.dealer-search-store {
	border-bottom: 1px solid $color-neutrals-100-tint;
	padding: $spacing-l 0;
}

h2 {
	font-weight: bold;
	margin-bottom: $spacing-s;
}

.details {
	color: $color-neutrals-600-shade;

	@include font-text-s;
}

.opening-hours {
	white-space: break-spaces;
}

.phone-number {
	margin-bottom: 4px;
	color: gray;
	text-decoration: none; /* Removes underline */
}
</style>
